import React from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import Representative from '../components/Representative';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules'; // ✅ 必要なモジュールをインポート
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/swiper-custom.css'; // Swiper のカスタムCSS
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      memberimg02: file(relativePath: { eq: "about/memberimg02.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      memberimg03: file(relativePath: { eq: "about/memberimg03.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      memberimg04: file(relativePath: { eq: "about/memberimg04.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      memberimg05: file(relativePath: { eq: "about/memberimg05.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      memberimg06: file(relativePath: { eq: "about/memberimg06.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
      memberimg07: file(relativePath: { eq: "about/memberimg07.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED)
        }
      }
    }
  `);

  const title =
    '事務所案内とメンバー紹介｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    'インヴォルブはウェブサイト制作やウェブマーケティングを行う集団です。私たちの仕事は、多くの人々にサービスや商品を提供したいと思うお客様の気持ちに寄り添い、 ウェブによってそれを多くの方々にお届けするお手伝いをすることです。';
  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: '事務所案内とメンバー紹介',
      url: 'https://www.involve-in.jp/about',
    },
  ];

  const members = [
    {
      name: '大天使ひなちゃん',
      role: 'Web designer',
      img: getImage(data.memberimg02),
      profile:
        '奈良のウェブ制作事務所INVOLVEに所属するWebデザイナー・コーダー。 業界歴15年。デザインとコーディングを手がけ、シンプルかつ美しい設計を得意とする。旅行系や美容系のデザイン会社での経験を活かし、多様なジャンルの制作に対応。簡単なフロントエンド開発にも精通し、スピーディな納品が強み。現在もWebデザイナーとして、クライアントのビジョンを形にするためのデザインを提供している。',
    },
    {
      name: '西原',
      role: 'Front End Engineer',
      img: getImage(data.memberimg03),
      profile:
        'Webデザイナー兼フロントエンドエンジニア。京都府出身。 大阪のデザイン会社やシステム開発会社での経験を活かし、現在はINVOLVEに在籍。Web系のシステム開発を得意とし、大規模サイトの制作経験も豊富。デザインからコーディング、フロントエンド開発まで幅広く対応し、機能性とビジュアルの両立を意識したサイト制作を手がける。ユーザー視点を重視した設計で、クライアントの課題を解決へと導く。',
    },
    {
      name: 'オオツカ',
      role: 'Web designer',
      img: getImage(data.memberimg07),
      profile:
        '関西出身、業界歴20年のWEBデザイナー。大阪のWEB制作会社で経験を積み、現在はINVOLVEに所属。目的に応じたデザインで、ビジネスやサービスの魅力を引き出すことを得意とする。丁寧なヒアリングを通じて、クライアントの想いを汲み取り、一緒に理想のサイトを形にすることを大切にしている。デザインだけでなく、使いやすさや運用しやすさにも配慮し、長く愛されるWEBサイトの制作を心がけている。',
    },
    {
      name: 'ズイショ',
      role: 'Writer / Web marketer',
      img: getImage(data.memberimg04),
      profile:
        '北海道出身のWebマーケッター・ライター。 大阪でWebマーケッターとして勤務しながら、副業としてライターとしても活動。業界歴15年。現在はINVOLVEに在籍し、マーケティング視点を活かしたコンテンツ制作を手がける。内容の本質を捉え、顧客の思いを的確に言葉で表現することを得意とする。データ分析とライティングスキルを融合させ、ターゲットに響く文章を生み出す。',
    },
    {
      name: 'ニシヤブ',
      role: 'Video Editor',
      img: getImage(data.memberimg05),
      profile:
        '関西出身の動画編集者。 デザイナーとして業界で10年の経験を積み、そのスキルを活かしてデザイン視点での動画編集を手がける。アニメーションを駆使した表現を得意とし、視覚的なインパクトとストーリー性を両立。クライアントの魅力を最大限に引き出し、伝わる映像を制作することを心がけている。ビジュアルとメッセージが一体となる編集で、効果的な動画コンテンツを提供。',
    },
    {
      name: 'ケビン',
      role: 'Systems engineer',
      img: getImage(data.memberimg06),
      profile:
        '関西出身。現在は東京で不動産会社とシステム会社を経営するITコンサルタント。 元大手SIerのプロジェクトマネージャーを経て、ベンチャーを起業しCOO兼CTOとして活躍。専門は金融・不動産、AI・機械学習、ITマネジメント全般。国立大学の非常勤講師として教育にも携わり、Webアプリ開発など幅広い分野で活動中。実務と経営の視点を融合し、最適なITソリューションを提供する。',
    },
  ];

  const outlineItems = [
    { label: '屋号', value: 'INVOLVE(インヴォルブ)' },
    { label: '創業', value: '2016年1月1日' },
    {
      label: '事業内容',
      value: `ホームページの企画・デザイン・制作
  システムの設計・開発
  サーバーの構築・管理・保守
  ウェブサイトの運営・運用支援
  ホームページ運営に関するコンサルティング
  集客やアクセス増加のためのマーケティング提案
  インターネット広告の出稿・運用
  動画制作（撮影・編集・アニメーション制作・ライブ配信）`,
    },
    { label: '代表者', value: '吉村 浩嗣' },
    { label: '所在地', value: '奈良県橿原市葛本町145-8' },
    { label: '適格請求書発行事業者登録番号', value: 'T8810679227700' },
    { label: '電話', value: '080-4267-7229' },
    { label: 'メール', value: 'info@involve-in.jp' },
    {
      label: 'ウェブサイト',
      value: (
        <Link to="/" className="text-blue-600 hover:text-blue-800">
          https://www.involve-in.jp/
        </Link>
      ),
    },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/about/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/about" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      <div className="max-w-screen-xl w-full px-16 mx-auto py-32 bg-white bg-opacity-50">
        <section className="greeting container mx-auto py-24 px-6 text-center">
          <h1 className="font-roboto text-6xl md:text-[10rem] font-bold mb-12">
            About
          </h1>
          <h2 className="text-3xl font-bold mb-6 text-center">
            私たちについて
          </h2>
          <p className="w-full sm:w-4/5 md:w-4/5 text-[1.4rem] md:text-[2rem] sm:text-[1.4rem] leading-relaxed mx-auto">
            INVOLVEは、Web制作とマーケティングのプロフェッショナルチーム。お客様の想いに寄り添い、ビジネスの成長を共に考え、最適な戦略を提案します。
          </p>
          <p className="w-full sm:w-4/5 md:w-4/5 text-[1.4rem] md:text-[2rem] sm:text-[1.4rem] leading-relaxed mx-auto">
            「巻き込む」「巻き込まれる」——
            共に創る一体感を大切に、あなたのプロジェクトを成功へ導きます。
          </p>
        </section>
        <Representative />

        {/* Team セクション */}
        <section className="max-w-screen-2xl w-full px-8 md:px-16 mx-auto py-40 bg-white bg-opacity-50">
          <h2 className="text-3xl font-bold mb-8 text-center text-black">
            <span className="font-roboto text-8xl font-bold block">Team</span>
            チーム紹介
          </h2>

          {/* ✅ 1024px 以上なら `Grid` 表示、それ以下なら `Swiper` */}
          <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 lg:gap-16">
            {members.map((member, index) => (
              <article
                key={index}
                className="card p-10 bg-white border border-gray-300 rounded-2xl shadow-xl w-full max-w-2xl mx-auto"
              >
                <GatsbyImage
                  image={member.img}
                  alt={member.name}
                  className="w-full aspect-square object-cover rounded-xl mb-6"
                />

                <h3 className="text-4xl font-semibold text-gray-800">
                  {member.name}
                </h3>
                <p className="text-2xl font-medium mb-4 bg-gradient-to-r from-gray-300 to-gray-500 text-gray-900 px-6 py-2 rounded-md inline-block">
                  {member.role}
                </p>
                <p className="text-2xl text-gray-700">{member.profile}</p>
              </article>
            ))}
          </div>

          {/* ✅ スマホ・タブレットでは `Swiper` 表示 */}
          <div className="md:hidden">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              pagination={{ clickable: true }} // ✅ クリック可能なページネーション
              navigation={true} // ✅ 矢印ボタンを有効化
              modules={[Navigation, Pagination]} // ✅ modules の書き方を統一
              loop={false} // ✅ ループを無効化
              breakpoints={{
                640: { slidesPerView: 2 },
                1024: { slidesPerView: 3 },
              }}
              className="swiper-container"
            >
              {members.map((member, index) => (
                <SwiperSlide key={index}>
                  <article className="card p-10 bg-white border border-gray-300 rounded-2xl shadow-xl w-full max-w-3xl mx-auto">
                    <GatsbyImage
                      image={member.img}
                      alt={member.name}
                      className="w-full aspect-square object-cover rounded-xl mb-6"
                    />
                    <h3 className="text-4xl font-semibold text-gray-800">
                      {member.name}
                    </h3>
                    <p className="text-2xl font-medium mb-4 bg-gradient-to-r from-gray-300 to-gray-500 text-gray-900 px-6 py-2 rounded-md inline-block">
                      {member.role}
                    </p>
                    <p className="text-2xl text-gray-700">{member.profile}</p>
                  </article>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
        <section className="max-w-screen-lg w-full px-8 md:px-16 mx-auto py-32 bg-white bg-opacity-90">
          <h2 className="text-3xl font-bold mb-8 text-center text-black">
            <span className="font-roboto text-8xl font-bold block">
              OUTLINE
            </span>
            事務所概要
          </h2>

          {/* PCではテーブル表示、スマホではグリッド表示 */}
          <div className="hidden md:block">
            <table className="w-full border-collapse">
              <tbody>
                {outlineItems.map((item, index) => (
                  <tr key={index} className="border-b border-gray-700">
                    <th className="p-6 pr-8 text-left font-bold text-2xl md:text-3xl w-1/4 md:w-1/3 whitespace-nowrap">
                      {item.label}
                    </th>
                    <td className="p-6 text-2xl text-gray-800 w-3/4 md:w-2/3 whitespace-pre-line leading-loose">
                      {item.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="md:hidden grid gap-6">
            {outlineItems.map((item, index) => (
              <div key={index} className="grid grid-cols-1">
                <div className="p-4 text-2xl font-bold bg-black text-white text-center">
                  {item.label}
                </div>
                <div className="p-4 text-2xl text-gray-800 bg-gray-100 whitespace-pre-line leading-loose">
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default AboutPage;
