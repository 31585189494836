import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Representative = () => {
  const data = useStaticQuery(graphql`
    query {
      memberimg01: file(relativePath: { eq: "about/memberimg01.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 500, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `);

  const memberImage = getImage(data.memberimg01);

  const textRef = useRef(null);
  const messageRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power3.out', delay: 0.3 }
    );

    gsap.fromTo(
      imageRef.current,
      { opacity: 0, scale: 0.95, x: 50 },
      {
        opacity: 1,
        scale: 1,
        x: 0,
        duration: 1,
        ease: 'power3.out',
        delay: 0.5,
      }
    );

    gsap.fromTo(
      messageRef.current,
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, ease: 'power3.out', delay: 0.6 }
    );
  }, []);

  return (
    <section className="py-40 bg-white">
      <div className="container mx-auto px-8">
        {/* 見出し */}
        <h2
          ref={textRef}
          className="text-3xl font-bold mb-8 text-center text-black"
        >
          <span className="font-roboto text-8xl font-bold block">Message</span>
          代表者あいさつ
        </h2>

        {/* メインコンテンツ（PC時は「テキスト左・画像右」、スマホ時は「タイトル → 画像 → 文章」） */}
        <div className="flex flex-col md:flex-row-reverse items-center md:items-start md:space-x-32">
          {/* 画像（PCでは右、スマホではタイトルの下） */}
          <div ref={imageRef} className="md:w-1/3 w-full mt-12 md:mt-0">
            <GatsbyImage
              image={memberImage}
              alt="吉村浩嗣"
              className="w-full h-auto object-cover rounded-xl shadow-2xl"
            />
          </div>

          {/* テキストエリア（PCでは左、スマホでは画像の下） */}
          <div
            ref={messageRef}
            className="md:w-2/3 w-full text-left mt-12 md:mt-0"
          >
            <h3 className="text-5xl font-semibold text-gray-900 mb-8">
              吉村浩嗣
            </h3>
            <p className="text-3xl text-gray-600 mb-8 border-b-2 border-gray-500 inline-block">
              Web Producer / Web Marketer
            </p>

            <p className="text-3xl text-gray-700 leading-relaxed">
              2011年よりWEB制作の道を歩み始めた私は、
              「本当に届けたいサービス」を提供するチームを
              自らの手で一から作ろうと決意したのは2016年、29歳の頃。
            </p>

            <p className="mt-10 text-3xl text-gray-700 leading-relaxed">
              本当に必要なものを提案し、実現して形にする。
              それができない経験を、いくつもの組織の中で積み重ねた結果の決断でした。
              私たちインヴォルブが目指すのは、より人に届き、より人に響き、
              そしてより人と人とを繋ぐこと。
            </p>

            <p className="mt-10 text-4xl text-gray-900 font-bold">
              「involve you, involve us」
            </p>

            <p className="mt-10 text-3xl text-gray-700 leading-relaxed">
              29歳の時に「一人」信じて出発した気持ちを、
              「私たち」のスローガンとして掲げ、 これから先も走り続けます。
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Representative;
